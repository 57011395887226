<template>
  <v-layout class="d-flex justify-center align-center">
    <div class="display-1">coming soon!</div>
  </v-layout>
</template>

<script>
export default {};
</script>

<style>
</style>